export const title = {
  accessories: 'アクセサリー / 配件',
  bg: '背景 / 背景 ',
  blush: 'チーク/ 腮紅',
  clothes: '服装 / 衣服',
  eyebrow: 'まゆげ / 眉毛',
  eyes: '目 / 眼睛',
  face: '顔 / 臉',
  hair: '髪 / 頭髮',
  mole: 'ほくろ 、ヒゲ / 痣、鬍子	',
  mouth: '口 / 嘴',
  nose: '鼻 /	鼻子',
  ornament: '髪飾り /	頭飾',
  makeup: 'メーク / 臉妝',
  stamp: 'スタンプ / 貼紙',
};

module.exports = {
  face: [
    [
      require('./face/1/0.png').default,
      require('./face/1/1.PNG').default,
      require('./face/1/2.PNG').default,
      require('./face/1/3.PNG').default,
    ],
    [
      require('./face/2/0.png').default,
      require('./face/2/1.PNG').default,
      require('./face/2/2.PNG').default,
      require('./face/2/3.PNG').default,
    ],
    [
      require('./face/3/0.png').default,
      require('./face/3/1.PNG').default,
      require('./face/3/2.PNG').default,
      require('./face/3/3.PNG').default,
    ],
  ],
  hair: [
    [
      require('./hair/1/1.PNG').default,
      require('./hair/1/10.PNG').default,
      require('./hair/1/11.PNG').default,
      require('./hair/1/12.PNG').default,
      require('./hair/1/13.PNG').default,
      require('./hair/1/2.PNG').default,
      require('./hair/1/3.PNG').default,
      require('./hair/1/4.PNG').default,
      require('./hair/1/5.PNG').default,
      require('./hair/1/6.PNG').default,
      require('./hair/1/7.PNG').default,
      require('./hair/1/8.PNG').default,
      require('./hair/1/9.PNG').default,
    ],
    [
      require('./hair/2/1.PNG').default,
      require('./hair/2/10.PNG').default,
      require('./hair/2/11.PNG').default,
      require('./hair/2/12.PNG').default,
      require('./hair/2/13.PNG').default,
      require('./hair/2/2.PNG').default,
      require('./hair/2/3.PNG').default,
      require('./hair/2/4.PNG').default,
      require('./hair/2/5.PNG').default,
      require('./hair/2/6.PNG').default,
      require('./hair/2/7.PNG').default,
      require('./hair/2/8.PNG').default,
      require('./hair/2/9.PNG').default,
    ],
    [
      require('./hair/3/1.PNG').default,
      require('./hair/3/10.PNG').default,
      require('./hair/3/11.PNG').default,
      require('./hair/3/12.PNG').default,
      require('./hair/3/13.PNG').default,
      require('./hair/3/2.PNG').default,
      require('./hair/3/3.PNG').default,
      require('./hair/3/4.PNG').default,
      require('./hair/3/5.PNG').default,
      require('./hair/3/6.PNG').default,
      require('./hair/3/7.PNG').default,
      require('./hair/3/8.PNG').default,
      require('./hair/3/9.PNG').default,
    ],
    [
      require('./hair/4/1.PNG').default,
      require('./hair/4/10.PNG').default,
      require('./hair/4/11.PNG').default,
      require('./hair/4/12.PNG').default,
      require('./hair/4/13.PNG').default,
      require('./hair/4/2.PNG').default,
      require('./hair/4/3.PNG').default,
      require('./hair/4/4.PNG').default,
      require('./hair/4/5.PNG').default,
      require('./hair/4/6.PNG').default,
      require('./hair/4/7.PNG').default,
      require('./hair/4/8.PNG').default,
      require('./hair/4/9.PNG').default,
    ],
    [
      require('./hair/5/1.PNG').default,
      require('./hair/5/10.PNG').default,
      require('./hair/5/11.PNG').default,
      require('./hair/5/12.PNG').default,
      require('./hair/5/13.PNG').default,
      require('./hair/5/2.PNG').default,
      require('./hair/5/3.PNG').default,
      require('./hair/5/4.PNG').default,
      require('./hair/5/5.PNG').default,
      require('./hair/5/6.PNG').default,
      require('./hair/5/7.PNG').default,
      require('./hair/5/8.PNG').default,
      require('./hair/5/9.PNG').default,
    ],
    [
      require('./hair/6/1.PNG').default,
      require('./hair/6/10.PNG').default,
      require('./hair/6/11.PNG').default,
      require('./hair/6/12.PNG').default,
      require('./hair/6/13.PNG').default,
      require('./hair/6/2.PNG').default,
      require('./hair/6/3.PNG').default,
      require('./hair/6/4.PNG').default,
      require('./hair/6/5.PNG').default,
      require('./hair/6/6.PNG').default,
      require('./hair/6/7.PNG').default,
      require('./hair/6/8.PNG').default,
      require('./hair/6/9.PNG').default,
    ],
    [
      require('./hair/7/1.PNG').default,
      require('./hair/7/10.PNG').default,
      require('./hair/7/11.PNG').default,
      require('./hair/7/12.PNG').default,
      require('./hair/7/13.PNG').default,
      require('./hair/7/2.PNG').default,
      require('./hair/7/3.PNG').default,
      require('./hair/7/4.PNG').default,
      require('./hair/7/5.PNG').default,
      require('./hair/7/6.PNG').default,
      require('./hair/7/7.PNG').default,
      require('./hair/7/8.PNG').default,
      require('./hair/7/9.PNG').default,
    ],
    [
      require('./hair/8/1-min.PNG').default,
      require('./hair/8/10-min.PNG').default,
      require('./hair/8/11-min.PNG').default,
      require('./hair/8/12-min.PNG').default,
      require('./hair/8/13-min.PNG').default,
      require('./hair/8/2-min.PNG').default,
      require('./hair/8/3-min.PNG').default,
      require('./hair/8/4-min.PNG').default,
      require('./hair/8/5-min.PNG').default,
      require('./hair/8/6-min.PNG').default,
      require('./hair/8/7-min.PNG').default,
      require('./hair/8/8-min.PNG').default,
      require('./hair/8/9-min.PNG').default,
    ],
    [
      require('./hair/9/1.PNG').default,
      require('./hair/9/10.PNG').default,
      require('./hair/9/11.PNG').default,
      require('./hair/9/12.PNG').default,
      require('./hair/9/13.PNG').default,
      require('./hair/9/2.PNG').default,
      require('./hair/9/3.PNG').default,
      require('./hair/9/4.PNG').default,
      require('./hair/9/5.PNG').default,
      require('./hair/9/6.PNG').default,
      require('./hair/9/7.PNG').default,
      require('./hair/9/8.PNG').default,
      require('./hair/9/9.PNG').default,
    ],
    [
      require('./hair/10/1.PNG').default,
      require('./hair/10/10.PNG').default,
      require('./hair/10/11.PNG').default,
      require('./hair/10/12.PNG').default,
      require('./hair/10/13.PNG').default,
      require('./hair/10/2.PNG').default,
      require('./hair/10/3.PNG').default,
      require('./hair/10/4.PNG').default,
      require('./hair/10/5.PNG').default,
      require('./hair/10/6.PNG').default,
      require('./hair/10/7.PNG').default,
      require('./hair/10/8.PNG').default,
      require('./hair/10/9.PNG').default,
    ],
    [
      require('./hair/11/1.PNG').default,
      require('./hair/11/10.PNG').default,
      require('./hair/11/11.PNG').default,
      require('./hair/11/12.PNG').default,
      require('./hair/11/13.PNG').default,
      require('./hair/11/2.PNG').default,
      require('./hair/11/3.PNG').default,
      require('./hair/11/4.PNG').default,
      require('./hair/11/5.PNG').default,
      require('./hair/11/6.PNG').default,
      require('./hair/11/7.PNG').default,
      require('./hair/11/8.PNG').default,
      require('./hair/11/9.PNG').default,
    ],
    [
      require('./hair/12/1.PNG').default,
      require('./hair/12/10.PNG').default,
      require('./hair/12/11.PNG').default,
      require('./hair/12/12.PNG').default,
      require('./hair/12/13.PNG').default,
      require('./hair/12/2.PNG').default,
      require('./hair/12/3.PNG').default,
      require('./hair/12/4.PNG').default,
      require('./hair/12/5.PNG').default,
      require('./hair/12/6.PNG').default,
      require('./hair/12/7.PNG').default,
      require('./hair/12/8.PNG').default,
      require('./hair/12/9.PNG').default,
    ],
    [
      require('./hair/13/1.PNG').default,
      require('./hair/13/10.PNG').default,
      require('./hair/13/11.PNG').default,
      require('./hair/13/12.PNG').default,
      require('./hair/13/13.PNG').default,
      require('./hair/13/2.PNG').default,
      require('./hair/13/3.PNG').default,
      require('./hair/13/4.PNG').default,
      require('./hair/13/5.PNG').default,
      require('./hair/13/6.PNG').default,
      require('./hair/13/7.PNG').default,
      require('./hair/13/8.PNG').default,
      require('./hair/13/9.PNG').default,
    ],
    [
      require('./hair/14/1.PNG').default,
      require('./hair/14/10.PNG').default,
      require('./hair/14/11.PNG').default,
      require('./hair/14/12.PNG').default,
      require('./hair/14/13.PNG').default,
      require('./hair/14/2.PNG').default,
      require('./hair/14/3.PNG').default,
      require('./hair/14/4.PNG').default,
      require('./hair/14/5.PNG').default,
      require('./hair/14/6.PNG').default,
      require('./hair/14/7.PNG').default,
      require('./hair/14/8.PNG').default,
      require('./hair/14/9.PNG').default,
    ],
    [
      require('./hair/15/1.PNG').default,
      require('./hair/15/10.PNG').default,
      require('./hair/15/11.PNG').default,
      require('./hair/15/12.PNG').default,
      require('./hair/15/13.PNG').default,
      require('./hair/15/2.PNG').default,
      require('./hair/15/3.PNG').default,
      require('./hair/15/4.PNG').default,
      require('./hair/15/5.PNG').default,
      require('./hair/15/6.PNG').default,
      require('./hair/15/7.PNG').default,
      require('./hair/15/8.PNG').default,
      require('./hair/15/9.PNG').default,
    ],
    [
      require('./hair/16/1.PNG').default,
      require('./hair/16/10.PNG').default,
      require('./hair/16/11.PNG').default,
      require('./hair/16/12.PNG').default,
      require('./hair/16/13.PNG').default,
      require('./hair/16/2.PNG').default,
      require('./hair/16/3.PNG').default,
      require('./hair/16/4.PNG').default,
      require('./hair/16/5.PNG').default,
      require('./hair/16/6.PNG').default,
      require('./hair/16/7.PNG').default,
      require('./hair/16/8.PNG').default,
      require('./hair/16/9.PNG').default,
    ],
    [
      require('./hair/17/1.PNG').default,
      require('./hair/17/10.PNG').default,
      require('./hair/17/11.PNG').default,
      require('./hair/17/12.PNG').default,
      require('./hair/17/13.PNG').default,
      require('./hair/17/2.PNG').default,
      require('./hair/17/3.PNG').default,
      require('./hair/17/4.PNG').default,
      require('./hair/17/5.PNG').default,
      require('./hair/17/6.PNG').default,
      require('./hair/17/7.PNG').default,
      require('./hair/17/8.PNG').default,
      require('./hair/17/9.PNG').default,
    ],
    [
      require('./hair/18/1.PNG').default,
      require('./hair/18/10.PNG').default,
      require('./hair/18/11.PNG').default,
      require('./hair/18/12.PNG').default,
      require('./hair/18/13.PNG').default,
      require('./hair/18/2.PNG').default,
      require('./hair/18/3.PNG').default,
      require('./hair/18/4.PNG').default,
      require('./hair/18/5.PNG').default,
      require('./hair/18/6.PNG').default,
      require('./hair/18/7.PNG').default,
      require('./hair/18/8.PNG').default,
      require('./hair/18/9.PNG').default,
    ],
    [
      require('./hair/19/1.PNG').default,
      require('./hair/19/10.PNG').default,
      require('./hair/19/11.PNG').default,
      require('./hair/19/12.PNG').default,
      require('./hair/19/13.PNG').default,
      require('./hair/19/2.PNG').default,
      require('./hair/19/3.PNG').default,
      require('./hair/19/4.PNG').default,
      require('./hair/19/5.PNG').default,
      require('./hair/19/6.PNG').default,
      require('./hair/19/7.PNG').default,
      require('./hair/19/8.PNG').default,
      require('./hair/19/9.PNG').default,
    ],
    [
      require('./hair/20/1.PNG').default,
      require('./hair/20/10.PNG').default,
      require('./hair/20/11.PNG').default,
      require('./hair/20/12.PNG').default,
      require('./hair/20/13.PNG').default,
      require('./hair/20/2.PNG').default,
      require('./hair/20/3.PNG').default,
      require('./hair/20/4.PNG').default,
      require('./hair/20/5.PNG').default,
      require('./hair/20/6.PNG').default,
      require('./hair/20/7.PNG').default,
      require('./hair/20/8.PNG').default,
      require('./hair/20/9.PNG').default,
    ],
    [
      require('./hair/21/1.PNG').default,
      require('./hair/21/10.PNG').default,
      require('./hair/21/11.PNG').default,
      require('./hair/21/12.PNG').default,
      require('./hair/21/13.PNG').default,
      require('./hair/21/2.PNG').default,
      require('./hair/21/3.PNG').default,
      require('./hair/21/4.PNG').default,
      require('./hair/21/5.PNG').default,
      require('./hair/21/6.PNG').default,
      require('./hair/21/7.PNG').default,
      require('./hair/21/8.PNG').default,
      require('./hair/21/9.PNG').default,
    ],
    [
      require('./hair/22/1.PNG').default,
      require('./hair/22/10.PNG').default,
      require('./hair/22/11.PNG').default,
      require('./hair/22/12.PNG').default,
      require('./hair/22/13.PNG').default,
      require('./hair/22/2.PNG').default,
      require('./hair/22/3.PNG').default,
      require('./hair/22/4.PNG').default,
      require('./hair/22/5.PNG').default,
      require('./hair/22/6.PNG').default,
      require('./hair/22/7.PNG').default,
      require('./hair/22/8.PNG').default,
      require('./hair/22/9.PNG').default,
    ],
    [
      require('./hair/23/1.png').default,
      require('./hair/23/10.png').default,
      require('./hair/23/11.png').default,
      require('./hair/23/12.png').default,
      require('./hair/23/13.png').default,
      require('./hair/23/2.png').default,
      require('./hair/23/3.png').default,
      require('./hair/23/4.png').default,
      require('./hair/23/5.png').default,
      require('./hair/23/6.png').default,
      require('./hair/23/7.png').default,
      require('./hair/23/8.png').default,
      require('./hair/23/9.png').default,
    ],
    [
      require('./hair/24/1.png').default,
      require('./hair/24/10.png').default,
      require('./hair/24/11.png').default,
      require('./hair/24/12.png').default,
      require('./hair/24/13.png').default,
      require('./hair/24/2.png').default,
      require('./hair/24/3.png').default,
      require('./hair/24/4.png').default,
      require('./hair/24/5.png').default,
      require('./hair/24/6.png').default,
      require('./hair/24/7.png').default,
      require('./hair/24/8.png').default,
      require('./hair/24/9.png').default,
    ],
    [
      require('./hair/25/1.png').default,
      require('./hair/25/10.png').default,
      require('./hair/25/11.png').default,
      require('./hair/25/12.png').default,
      require('./hair/25/13.png').default,
      require('./hair/25/2.png').default,
      require('./hair/25/3.png').default,
      require('./hair/25/4.png').default,
      require('./hair/25/5.png').default,
      require('./hair/25/6.png').default,
      require('./hair/25/7.png').default,
      require('./hair/25/8.png').default,
      require('./hair/25/9.png').default,
    ],
    [
      require('./hair/26/1.png').default,
      require('./hair/26/10.png').default,
      require('./hair/26/11.png').default,
      require('./hair/26/12.png').default,
      require('./hair/26/13.png').default,
      require('./hair/26/2.png').default,
      require('./hair/26/3.png').default,
      require('./hair/26/4.png').default,
      require('./hair/26/5.png').default,
      require('./hair/26/6.png').default,
      require('./hair/26/7.png').default,
      require('./hair/26/8.png').default,
      require('./hair/26/9.png').default,
    ],
    [
      require('./hair/27/1.png').default,
      require('./hair/27/10.png').default,
      require('./hair/27/11.png').default,
      require('./hair/27/12.png').default,
      require('./hair/27/13.png').default,
      require('./hair/27/2.png').default,
      require('./hair/27/3.png').default,
      require('./hair/27/4.png').default,
      require('./hair/27/5.png').default,
      require('./hair/27/6.png').default,
      require('./hair/27/7.png').default,
      require('./hair/27/8.png').default,
      require('./hair/27/9.png').default,
    ],
    [
      require('./hair/28/1.png').default,
      require('./hair/28/10.png').default,
      require('./hair/28/11.png').default,
      require('./hair/28/12.png').default,
      require('./hair/28/13.png').default,
      require('./hair/28/2.png').default,
      require('./hair/28/3.png').default,
      require('./hair/28/4.png').default,
      require('./hair/28/5.png').default,
      require('./hair/28/6.png').default,
      require('./hair/28/7.png').default,
      require('./hair/28/8.png').default,
      require('./hair/28/9.png').default,
    ],
    [
      require('./hair/29/1.png').default,
      require('./hair/29/10.png').default,
      require('./hair/29/11.png').default,
      require('./hair/29/12.png').default,
      require('./hair/29/13.png').default,
      require('./hair/29/2.png').default,
      require('./hair/29/3.png').default,
      require('./hair/29/4.png').default,
      require('./hair/29/5.png').default,
      require('./hair/29/6.png').default,
      require('./hair/29/7.png').default,
      require('./hair/29/8.png').default,
      require('./hair/29/9.png').default,
    ],
    [
      require('./hair/30/1.png').default,
      require('./hair/30/10.png').default,
      require('./hair/30/11.png').default,
      require('./hair/30/12.png').default,
      require('./hair/30/13.png').default,
      require('./hair/30/2.png').default,
      require('./hair/30/3.png').default,
      require('./hair/30/4.png').default,
      require('./hair/30/5.png').default,
      require('./hair/30/6.png').default,
      require('./hair/30/7.png').default,
      require('./hair/30/8.png').default,
      require('./hair/30/9.png').default,
    ],
    [
      require('./hair/31/1.PNG').default,
      require('./hair/31/10.PNG').default,
      require('./hair/31/11.PNG').default,
      require('./hair/31/12.PNG').default,
      require('./hair/31/13.PNG').default,
      require('./hair/31/2.PNG').default,
      require('./hair/31/3.PNG').default,
      require('./hair/31/4.PNG').default,
      require('./hair/31/5.PNG').default,
      require('./hair/31/6.PNG').default,
      require('./hair/31/7.PNG').default,
      require('./hair/31/8.PNG').default,
      require('./hair/31/9.PNG').default,
    ],
    [
      require('./hair/32/1.PNG').default,
      require('./hair/32/10.PNG').default,
      require('./hair/32/11.PNG').default,
      require('./hair/32/12.PNG').default,
      require('./hair/32/13.PNG').default,
      require('./hair/32/2.PNG').default,
      require('./hair/32/3.PNG').default,
      require('./hair/32/4.PNG').default,
      require('./hair/32/5.PNG').default,
      require('./hair/32/6.PNG').default,
      require('./hair/32/7.PNG').default,
      require('./hair/32/8.PNG').default,
      require('./hair/32/9.PNG').default,
    ],
    [
      require('./hair/33/1.PNG').default,
      require('./hair/33/10.PNG').default,
      require('./hair/33/11.PNG').default,
      require('./hair/33/12.PNG').default,
      require('./hair/33/13.PNG').default,
      require('./hair/33/2.PNG').default,
      require('./hair/33/3.PNG').default,
      require('./hair/33/4.PNG').default,
      require('./hair/33/5.PNG').default,
      require('./hair/33/6.PNG').default,
      require('./hair/33/7.PNG').default,
      require('./hair/33/8.PNG').default,
      require('./hair/33/9.PNG').default,
    ],
    [
      require('./hair/34/1.PNG').default,
      require('./hair/34/10.PNG').default,
      require('./hair/34/11.PNG').default,
      require('./hair/34/12.PNG').default,
      require('./hair/34/13.PNG').default,
      require('./hair/34/2.PNG').default,
      require('./hair/34/3.PNG').default,
      require('./hair/34/4.PNG').default,
      require('./hair/34/5.PNG').default,
      require('./hair/34/6.PNG').default,
      require('./hair/34/7.PNG').default,
      require('./hair/34/9.PNG').default,
    ],
  ],
  eyes: [
    require('./eyes/1.PNG').default,
    require('./eyes/10.PNG').default,
    require('./eyes/11.PNG').default,
    require('./eyes/12.PNG').default,
    require('./eyes/13.PNG').default,
    require('./eyes/14.PNG').default,
    require('./eyes/15.PNG').default,
    require('./eyes/2.PNG').default,
    require('./eyes/3.PNG').default,
    require('./eyes/4.PNG').default,
    require('./eyes/5.PNG').default,
    require('./eyes/6.PNG').default,
    require('./eyes/7.PNG').default,
    require('./eyes/8.PNG').default,
    require('./eyes/9.PNG').default,
  ],
  eyebrow: [
    [require('./eyebrow/1/1.png').default],
    [
      require('./eyebrow/2/1.png').default,
      require('./eyebrow/2/10.png').default,
      require('./eyebrow/2/11.png').default,
      require('./eyebrow/2/12.png').default,
      require('./eyebrow/2/13.png').default,
      require('./eyebrow/2/2.png').default,
      require('./eyebrow/2/3.png').default,
      require('./eyebrow/2/4.png').default,
      require('./eyebrow/2/5.png').default,
      require('./eyebrow/2/6.png').default,
      require('./eyebrow/2/7.png').default,
      require('./eyebrow/2/8.png').default,
      require('./eyebrow/2/9.png').default,
    ],
    [require('./eyebrow/3/IMG_0519.png').default],
    [
      require('./eyebrow/4/1.png').default,
      require('./eyebrow/4/10.png').default,
      require('./eyebrow/4/11.png').default,
      require('./eyebrow/4/12.png').default,
      require('./eyebrow/4/13.png').default,
      require('./eyebrow/4/2.png').default,
      require('./eyebrow/4/3.png').default,
      require('./eyebrow/4/4.png').default,
      require('./eyebrow/4/5.png').default,
      require('./eyebrow/4/6.png').default,
      require('./eyebrow/4/7.png').default,
      require('./eyebrow/4/8.png').default,
      require('./eyebrow/4/9.png').default,
    ],
    [
      require('./eyebrow/5/1.png').default,
      require('./eyebrow/5/10.png').default,
      require('./eyebrow/5/11.png').default,
      require('./eyebrow/5/12.png').default,
      require('./eyebrow/5/13.png').default,
      require('./eyebrow/5/2.png').default,
      require('./eyebrow/5/3.png').default,
      require('./eyebrow/5/4.png').default,
      require('./eyebrow/5/5.png').default,
      require('./eyebrow/5/6.png').default,
      require('./eyebrow/5/7.png').default,
      require('./eyebrow/5/8.png').default,
      require('./eyebrow/5/9.png').default,
    ],
    [require('./eyebrow/6/IMG_0560.png').default],
    [
      require('./eyebrow/7/1.png').default,
      require('./eyebrow/7/10.png').default,
      require('./eyebrow/7/11.png').default,
      require('./eyebrow/7/12.png').default,
      require('./eyebrow/7/13.png').default,
      require('./eyebrow/7/2.png').default,
      require('./eyebrow/7/3.png').default,
      require('./eyebrow/7/4.png').default,
      require('./eyebrow/7/5.png').default,
      require('./eyebrow/7/6.png').default,
      require('./eyebrow/7/7.png').default,
      require('./eyebrow/7/8.png').default,
      require('./eyebrow/7/9.png').default,
    ],
    [
      require('./eyebrow/8/1.png').default,
      require('./eyebrow/8/10.png').default,
      require('./eyebrow/8/11.png').default,
      require('./eyebrow/8/12.png').default,
      require('./eyebrow/8/13.png').default,
      require('./eyebrow/8/2.png').default,
      require('./eyebrow/8/3.png').default,
      require('./eyebrow/8/4.png').default,
      require('./eyebrow/8/5.png').default,
      require('./eyebrow/8/6.png').default,
      require('./eyebrow/8/7.png').default,
      require('./eyebrow/8/8.png').default,
      require('./eyebrow/8/9.png').default,
    ],
    [require('./eyebrow/9/IMG_0533.png').default],
  ],
  nose: [
    require('./nose/0.png').default,
    require('./nose/1.PNG').default,
    require('./nose/2.PNG').default,
    require('./nose/3.PNG').default,
    require('./nose/4.PNG').default,
    require('./nose/5.PNG').default,
    require('./nose/6.PNG').default,
    require('./nose/7.PNG').default,
  ],
  mouth: [
    require('./mouth/1.PNG').default,
    require('./mouth/2.PNG').default,
    require('./mouth/3.PNG').default,
    require('./mouth/4.PNG').default,
    require('./mouth/5.PNG').default,
    require('./mouth/6.PNG').default,
    require('./mouth/7.PNG').default,
    require('./mouth/8.PNG').default,
    require('./mouth/9.PNG').default,
    require('./mouth/10.PNG').default,
    require('./mouth/11.PNG').default,
    require('./mouth/12.PNG').default,
    require('./mouth/13.PNG').default,
  ],
  blush: [
    require('./blush/0.png').default,
    require('./blush/1.PNG').default,
    require('./blush/2.PNG').default,
    require('./blush/3.PNG').default,
    require('./blush/4.PNG').default,
    require('./blush/5.PNG').default,
    require('./blush/6.PNG').default,
    require('./blush/7.PNG').default,
    require('./blush/8.PNG').default,
    require('./blush/9.PNG').default,
  ],
  mole: [
    require('./mole/0.png').default,
    require('./mole/1.PNG').default,
    require('./mole/2.PNG').default,
    require('./mole/3.PNG').default,
    require('./mole/4.PNG').default,
    require('./mole/5.PNG').default,
  ],
  clothes: [
    require('./clothes/1.PNG').default,
    require('./clothes/2.PNG').default,
    require('./clothes/3.PNG').default,
    require('./clothes/4.PNG').default,
    require('./clothes/5.PNG').default,
    require('./clothes/6.PNG').default,
    require('./clothes/7.PNG').default,
    require('./clothes/8.PNG').default,
    require('./clothes/9.PNG').default,
    require('./clothes/10.PNG').default,
    require('./clothes/11.PNG').default,
    require('./clothes/12.PNG').default,
    require('./clothes/13.PNG').default,
    require('./clothes/14.PNG').default,
    require('./clothes/15.PNG').default,
    require('./clothes/16.PNG').default,
    require('./clothes/17.PNG').default,
    require('./clothes/18.PNG').default,
    require('./clothes/19.PNG').default,
    require('./clothes/20.PNG').default,
    require('./clothes/21.PNG').default,
    require('./clothes/22.PNG').default,
    require('./clothes/23.PNG').default,
    require('./clothes/24.PNG').default,
    require('./clothes/25.PNG').default,
    require('./clothes/26.PNG').default,
    require('./clothes/27.PNG').default,
  ],
  accessories: [
    require('./accessories/0.png').default,
    require('./accessories/1.PNG').default,
    require('./accessories/2.PNG').default,
    require('./accessories/3.PNG').default,
    require('./accessories/4.PNG').default,
  ],
  makeup: [
    require('./makeup/0.png').default,
    require('./makeup/1.PNG').default,
    require('./makeup/2.PNG').default,
    require('./makeup/3.PNG').default,
    require('./makeup/4.PNG').default,
    require('./makeup/5.PNG').default,
    require('./makeup/6.png').default,
  ],
  ornament: [
    require('./ornament/0.png').default,
    require('./ornament/1.PNG').default,
    require('./ornament/10.PNG').default,
    require('./ornament/11.PNG').default,
    require('./ornament/12.PNG').default,
    require('./ornament/13.PNG').default,
    require('./ornament/2.PNG').default,
    require('./ornament/3.PNG').default,
    require('./ornament/4.PNG').default,
    require('./ornament/5.PNG').default,
    require('./ornament/6.PNG').default,
    require('./ornament/7.PNG').default,
    require('./ornament/8.PNG').default,
    require('./ornament/9.PNG').default,
  ],
  stamp: [
    require('./stamp/0.png').default,
    require('./stamp/1.PNG').default,
    require('./stamp/2.PNG').default,
    require('./stamp/3.PNG').default,
  ],
  bg: [
    require('./bg/1.PNG').default,
    require('./bg/2.PNG').default,
    require('./bg/3.PNG').default,
    require('./bg/4.PNG').default,
    require('./bg/5.PNG').default,
    require('./bg/6.PNG').default,
    require('./bg/7.PNG').default,
    require('./bg/8.PNG').default,
    require('./bg/9.png').default,
    require('./bg/10.png').default,
  ],
};
